<template>
  <!-- 复合搜索框 -->
  <span
    class="owninputbox flex"
    :style="{'width':styles.width,'height':styles.height}"
    :class="styles.width=='500px'?'wid-500':''"
  >
    <span class="owninputbox_selested">
      <!-- , -->
      <a-dropdown
        :overlayStyle='selectedvalue==1? {"marginTop":"-15px","marginLeft":"-20px" }:{"marginTop":"-15px","marginLeft":"-20px","min-width":"85px"}'
        overlayClassName="mydropdown"
        placements="bottomRight"
        :destroyPopupOnHide="true"
      >
        <span
          class="owninputbox_search"
          style="display:inline-block;height: 28px;"
        >
          <!--  width : 103px -->
          <i style="margin-left:8px"> </i>
          <!-- {{activeStatus1=='2'?'商品码':'商品名称'}} -->
          <span class="oeninputbox_select_txt"> {{ transfer.activeStatus1.name }}</span>
          <i
            class="meiye-icon  meiye-xiala mgr-8"
            style="width:10px;font-size: 12px;"
          ></i>
        </span>
        <a-menu
          slot="overlay"
          :default-selected-keys="['1']"
          @click="handleMenuClickSearch"
          :selected-keys="selectedvalue"
        >
          <a-menu-item
            v-for="(item) in selectArr"
            @click=';transfer.activeStatus1=item;selectedvalue=item.id;placeholderValue=item.placeholder;'
            :key="item.id"
            :value="item.name"
            style="text-align:center;margin: 0 5px;padding:5px 0;border-radius: 3px;"
          >{{item.name}}
          </a-menu-item>
        </a-menu>

      </a-dropdown>
    </span>
    <a-input
      @search="getList"
      @pressEnter="getList"
      v-model="transfer.value"
      :placeholder="placeholderValue"
      class="flex-1 ant-input2  "
    >
      <i
        slot="suffix"
        @click="getList"
        class="meiye-icon meiye-sousuo pointer"
      ></i>
    </a-input>
  </span>

</template>

<script>  
export default {
  name: "ComposeInput",
  props: {
    selectArr: {
      type: Array,
      default: () => { return [] },
    },
    styles: {
      type: Object,
      default: function () {
        return { 'width': '401px', 'height': '32px' }
      }

    },
    placeholderValue: {
      type: String,
      default: '请输入商品名称',
    },
  },
  data() {
    return {
      transfer: {
        activeStatus1: { name: '' },
        value: "",
      },
      selectedvalue: ['1'],
    }
  },
  created() {
    console.log(this.selectArr, '////this.selectArr');

    this.transfer.activeStatus1 = this.selectArr[0]
  },
  methods: {
    getList() {
      this.$emit("getList", this.transfer)
    },
    getList2() {
      this.$emit("getList", this.transfer)
    },
    handleMenuClickSearch({ key }) {
      console.log(key);
      console.log(this.transfer.activeStatus1);
      this.$emit("handleMenuClickSearch", this.transfer.activeStatus1)

    },
  },

};
</script>

<style scoped lang="less" >
.owninputbox {
  // display: inline-block;
  background: #f5f7f7;
  // width: auto !important;
  height: 32px;
  line-height: 32px;
  background: #f5f7f7;
  box-shadow: inset 0px 3px 0px 0px rgba(76, 124, 121, 0.1);
  border-radius: 4px;
  border: 1px solid #b0c3c2;
  margin-right: 20px;
  display: flex !important;
  align-items: center;
  .meiye-icon {
    color: @fontColor4;
  }
  .meiye-sousuo {
    color: @fontColor5;
  }
  .owninputbox_selested {
    cursor: pointer;
    // width: 103px;
    border-right: 1px solid #b0c3c2;
    display: inline-block;
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: @fontColor4;
    line-height: 22px;
    position: relative;
    &:hover {
      .meiye-icon {
        color: @primaryColor;
      }
    }
    // padding-left: 8px;
  }
  .owninputbox_search {
    box-shadow: none;
    width: 100%;
    text-align: left;

    .meiye-xiala {
      // position: absolute;
      // right: 12px;
      // top: 0px;
      transition: 0s;
      transform-origin: center;
      transform: rotateZ(0deg);
    }
  }
  .owninputbox_search.ant-dropdown-open {
    color: @selectdownfontAColor;
    .meiye-xiala {
      // position: absolute;
      // right: 12px;
      // top: 0px;
      color: @primaryColor;
      transition: 0s;
      transform-origin: center;
      transform: rotateZ(180deg);
    }
  }
  .ant-input2 {
    height: 22px;
    border: none !important;
    box-shadow: none !important;
    background: #f5f7f7;
    /deep/ .ant-input {
      height: 22px;
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }
  &:hover {
    border: 1px solid @primaryColor;
    .meiye-sousuo {
      color: @primaryColor;
    }
  }
}
.wid-500 {
  // width: 500px !important;
}
.oeninputbox_select_txt {
  font-size: 14px;
  margin-top: 2px;
  position: inherit;
  margin-right: 15px;
}
.wid-284 {
  width: 284px;
}
/deep/ .mydropdown {
  min-width: 85px !important;
}
</style>