import request from '@/utils/request'

// 分类 复制
export function GetGoodsCopy(data) {
  return request({
    method: 'post',
    url: '/goods/proposal/copy',
    data
  })
}
// 分类 列表
export function GetGoodslist(data) {
  return request({
    method: 'post',
    url: '/goods/category/list',
    data
  })
}
//分类   详情 
export function goodsInfo(data) {
  return request({
    method: 'post',
    url: '/goods/category/show',
    data
  })
}
// 分类 创建
export function Creategoods(data) {
  return request({
    method: 'post',
    url: '/goods/category/create',
    data
  })
}
// 分类 编辑 
export function UpdateCustom(data) {
  return request({
    method: 'post',
    url: '/goods/category/update',
    data
  })
}
// 分类 类型选择
export function GetgoodsoptionsList(data) {
  return request({
    method: 'post',
    url: '/goods/category/category_options',
    data
  })
}


// 品牌  
export function Getbrandlist(data) {
  return request({
    method: 'post',
    url: '/goods/brand/list',
    data
  })
}
// 品牌详情 
export function brandInfo(data) {
  return request({
    method: 'post',
    url: '/goods/brand/show',
    data
  })
}
// 品牌 创建
export function Createbrand(data) {
  return request({
    method: 'post',
    url: '/goods/brand/create',
    data
  })
}
// 品牌 编辑 
export function Updatebrand(data) {
  return request({
    method: 'post',
    url: '/goods/brand/update',
    data
  })
}
// 品牌 删除
export function Deletebrand(data) { //api/goods/brand/delete
  return request({
    url: '/goods/brand/delete',
    method: 'post',
    data
  })
}

// 管理  商品列表
export function GetmanageGoodslist(data) {
  return request({
    method: 'post',
    url: '/goods/goods/list',
    data,
    // headerType:'json'/
  })
}
// 管理 详情 
export function GoodsInfo(data) {
  return request({
    method: 'post',
    url: '/goods/goods/show',
    data
  })
}
// 管理 创建
export function CreateGoods(data) {
  // data = JSON.stringify(data)
  // data =data.replace(/[\'\"\\\/\b\f\n\r\t]/g, '');
  // console.log(JSON.stringify(data), '......管理2222');
  return request({
    method: 'post',
    url: '/goods/goods/create',
    data
  })
}
// 管理 编辑 
export function UpdateGoods(data) {
  return request({
    method: 'post',
    url: '/goods/goods/update',
    data
  })
}
// 管理 删除 /api/goods/goods/delete
export function DeLeteGoods(data) {
  return request({
    method: 'post',
    url: '/goods/goods/delete',
    data
  })
}
// 管理 批量上下架 batchUpdateStatus
export function BatchGoods(data) {
  return request({
    method: 'post',
    url: '/goods/goods/batchUpdateStatus',
    data
  })
}
// 管理 单个上架  
export function Siglegrouding(data) {
  return request({
    method: 'post',
    url: '/goods/goods/update_company',
    data
  })
}
// 管理 多个批量上架  
export function Allgrouding(data) {
  return request({
    method: 'post',
    url: '/goods/goods/push_company',
    data
  })
}
// 管理 商品上架详情
export function showPush(data) {
  return request({
    method: 'post',
    url: '/goods/goods/show_push',
    data
  })
}
// 管理 商品授权到公司
export function pushCompany(data) {
  return request({
    method: 'post',
    url: '/goods/goods/push_company',
    data
  })
}
// 管理 商品授权信息变更
export function editPoolGoods(data) {
  return request({
    method: 'post',
    url: '/goods/goods/edit_pool_goods',
    data
  })
}
// 管理 商品取消指定公司授权
export function delCompanyGoods(data) {
  return request({
    method: 'post',
    url: '/goods/goods/del_company_goods',
    data
  })
}
// 管理 商品上架门店删除
// export function showPush(data) {
//   return request({
//     method: 'post',
//     url: '/goods/goods/showPush',
//     data
//   })
// }


// 管理 查询所有规格 /goods/goods/product_list

export function Allproduct(data) {
  return request({
    method: 'post',
    url: '/goods/goods/product_list',
    data
  })
}

// 管理 品牌选择
export function GetgoodsbrandsoptionsList(data) {
  return request({
    method: 'post',
    url: '/goods/brand/brand_options',
    data
  })
}
// 商品分类选择接口
export function GetgoodscategoryoptionsList(data) {
  return request({
    method: 'post',
    url: '/goods/category/category_options',
    data
  })
}
//财务分类选择接口
export function GetgoodsfinancecategoryoptionsList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/category_options',
    data
  })
}
//门店分类选择接口
export function GetgoodsstorecategoryoptionsList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/category_options',
    data
  })
}


// 财务分类 列表 
export function GettypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/list',
    data
  })
}
// 财务分类 创建分类
export function CreatetypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/create',
    data
  })
}
// 财务分类 修改分类
export function UpdatetypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/update',
    data
  })
}
// 财务分类 分类详情
export function ShowtypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/show',
    data
  })
}
//财务分类 删除 
export function DelatetypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/delete',
    data
  })
}
//财务分类 树形 
export function TreetypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/category_options',
    data
  })
}
//财务分类 迁移 
export function MovetypeFinanceList(data) {
  return request({
    method: 'post',
    url: '/goods/finance_category/goods_migrate',
    data
  })
}


// 门店分类 列表 
export function GettypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/list',
    data
  })
}
// 门店分类 创建分类
export function CreatetypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/create',
    data
  })
}
// 门店分类 修改分类
export function UpdatetypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/update',
    data
  })
}
// 门店分类 分类详情
export function ShowtypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/show',
    data
  })
}
//门店分类 删除 
export function DelatetypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/delete',
    data
  })
}
//门店分类 迁移 
export function MovetypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/goods_migrate',
    data
  })
}
//门店分类 树形 
export function TreetypeStoreList(data) {
  return request({
    method: 'post',
    url: '/goods/category/category_options',
    data
  })
}

// 商城分类 列表  
export function GettypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/list',
    data
  })
}
// 商城分类 创建分类
export function CreatetypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/create',
    data
  })
}
// 商城分类 修改分类
export function UpdatetypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/update',
    data
  })
}
// 商城分类 分类详情
export function ShowtypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/show',
    data
  })
}
//商城分类 删除 
export function DelatetypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/delete',
    data
  })
}
//商城分类 树形 
export function TreetypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/category_options',
    data
  })
}
//商城分类 迁移 
export function MovetypeMallList(data) {
  return request({
    method: 'post',
    url: '/goods/store_category/goods_migrate',
    data
  })
}

//方案   详情 
export function goodsInfoProposal(data) {
  return request({
    method: 'post',
    url: '/goods/proposal/show',
    data
  })
}
// 方案 创建
export function CreateGoodsProposal(data) {
  return request({
    method: 'post',
    url: '/goods/proposal/create',
    data
  })
}
// 方案 编辑 
export function UpdateCustomProposal(data) {
  return request({
    method: 'post',
    url: '/goods/proposal/update',
    data
  })
}
// 储值卡  详情 
// /goods/stored/money/create
export function cardsInfoProposal(data) {
  return request({
    method: 'post',
    url: '/goods/stored/money/show',
    data
  })
}
// 储值卡 创建
export function CreatecardsProposal(data) {
  return request({
    method: 'post',
    url: '/goods/stored/money/create',
    data
  })
}
// 储值卡 编辑 
export function UpdatcardsProposal(data) {
  return request({
    method: 'post',
    url: '/goods/stored/money/update',
    data
  })
}
// 方案 上架 商品包含规格选择接口
export function Upcardswithproduct(data) {
  return request({
    method: 'post',
    url: '/goods/goods/list_with_product',
    data
  })
}
//上架 商品标签选择接口 
export function Gettagoptions(data) {
  return request({
    method: 'post',
    url: '/goods/tag/tag_options',
    data
  })
}